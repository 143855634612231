/* Add application styles & imports to this file! */
@import 'simple-keyboard/build/css/index.css';

/* You can add global styles to this file, and also import other style files */
html, body {
    margin: 0;
    height: 100%;
    background-color: black;
    color: white;
    overflow: hidden;
    font-family: Arial, Helvetica, sans-serif;
}

.header-img {
    position: relative;
    left: 10%;
    width: 80%;
}

.song-item {
    position: relative;
    color: black;
    background-image: url('https://storage.googleapis.com/vjrnb-assets/tela02e/campo_keyword.svg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 56px;
    margin-top: 1%;
    font-size: 1.2em;
}

@media only screen and (min-width: 900px) {
    .song-item {
      height: 79px;
      font-size: 1.4em;
    }
}
